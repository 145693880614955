$(document).ready(function() {

  $("article.themes").on("touchend", function(){
    if(touchmoved != true){
      window.location.href = $(this).attr("data-url");
     }
  }).on('touchmove', function(e){
     touchmoved = true;
  }).on('touchstart', function(){
     touchmoved = false;
  });

});
