$(document).ready(function() {

  $("#search_icon").click(function(e) {
    e.preventDefault();
  $('header .nav').toggleClass('display_none');
  $(".search_bar").toggleClass('display_flex');
  $(".search_bar").find($(".search_input")).focus();
  $(this).toggleClass('search_active');
});
});
