import React, { Component } from 'react'
import axios from 'axios'

const robot_avatar = require('../../assets/images/robot_default.svg')
const human_avatar = require('../../assets/images/human.svg')
const arrow = require('../../assets/images/arrow.svg')

const HEARTS = "💕"
const THINK = "🤔"
const ROLL = "🙄"
const INDEX = "☝️"
const LIKE = "👍"

Array.prototype.random = function () {
  return this[Math.floor((Math.random()*this.length))];
}
const botMessages = (type,state) => {
  switch (type) {
    case "initial":
      switch (state) {
        case "f":
          return ["Bok, ja sam SeZaM, virtualna asistentica koja ima veliko znanje o različitim temama vezanim uz mlade i seksualnost. Kako ti mogu pomoći?",
                  "Hej, ja sam SeZaM, virtualna asistentica koja puno toga zna o mladima i seksualnosti. Kako ti mogu pomoći?"].random()
        case "m":
          return ["Hej, ja sam SeZaM, virtualni asistent koji puno toga zna o mladima i seksualnosti. Kako ti mogu pomoći?"].random()
        break;
      }
      break;
    case "choose_tag":
      switch (state) {
        case "f":
          return [" Imam puno tekstova koji bi ti mogli dati odgovor na ovo pitanje, pa te molim da odabereš jedno od područja:",
                  " Imam puno tekstova koji nude odgovor na ovo pitanje. Molim te odaberi jedno od ponuđenih područja:",
                  " Pronašla sam puno tekstova koji nude odgovor na tvoje pitanje. Molim te odaberi jedno od ponuđenih područja:"].random()
        case "m":
          return [" Imam puno tekstova koji bi ti mogli dati odgovor na ovo pitanje, pa te molim da odabereš jedno od područja:",
                  " Imam puno tekstova koji nude odgovor na ovo pitanje. Molim te odaberi jedno od ponuđenih područja:",
                  " Pronašao sam puno tekstova koji bi ti mogli dati odgovor na ovo pitanje, pa te molim da odabereš jedno od područja:"].random()
        break;
      }
      break;
    case "found":
      switch (state) {
        case "f":
          return [" Evo, izvoli! Nadam se da ćeš u ovim tekstovima naći odgovor:",
                  " Evo, izvoli! Pronašla sam tekstove u kojima možeš potražiti odgovor."].random()
        case "m":
          return [" Evo, izvoli! Nadam se da ćeš u ovim tekstovima naći odgovor:",
                  " Evo, izvoli! Pronašao sam tekstove u kojima možeš potražiti odgovor."].random()
        break;
      }
      break;
    case "not_found":
      return ["Ne mogu pronaći odgovor na ovo pitanje, možeš li ga preformulirati?",
              "Ne mogu pronaći odgovor na ovo pitanje. Možeš li postaviti pitanje na drugačiji način?"].random()
      break;
    case "ask_for_email":
      return ["Nažalost i dalje ne znam! Ali znam tko zna, pa ako mi napišeš svoj email pitat ću SeZaM tim i javiti se na email.",
              "Žao mi je ali i dalje ne znam! Ali znam tko zna, pa ako mi ostaviš svoj email pitat ću SeZaM tim i odgovor ćeš dobiti mailom."].random()
      break;
    case "thanks":
      return ["Hvala! Očekuj odgovor na svoj email uskoro.",
              "Hvala! Odgovor uskoro stiže mailom."].random()
      break;
  }
}

const Messages = props => (
  props.messages.map((m, index) =>
    <div key={index} className={index%2==0 ? "robot_text" : "human_text"}>
      <div className="avatar">
        <img src={index%2==0 ? robot_avatar : human_avatar} />
      </div>
      <div>{m}</div>
    </div>
  )
)

const Input = props => {
  const input = <form className="question_bar">
    <input ref={props.messageInput} className="question_input" type="text" autoFocus onKeyDown={props.handleKeyDown} />
    <button type="button" className="black_button" onClick={props.sendMessage}>Pitaj</button>
  </form>
  return (props.visable === true) ? input : ""
}

class Chat extends React.Component {

  constructor(props) {
    super(props);
    this.gender_state = ['m', 'f'].random()
    this.messageInput = React.createRef();
    this.state = {
      messages: [botMessages('initial', this.gender_state)],
      noAnswer: 0,
      showInput: true
    }
  }

  publish = (message) => {
    this.setState({ messages: [...this.state.messages, message], showInput: false })
    let self = this
    setTimeout(() =>{ self.answer(0) }, Math.random()*300)
  }

  sendMessage = () => {
    const message = this.messageInput.current.value;
    this.publish(message)
  }

  handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      let m = e.target.value
      if(m === 'clear') {
        this.setState({messages: []})
      } else {
        this.publish(m)
      }
      e.target.value = ''
    }
  }

  answer = (url) => {
    let self = this
    const question = this.state.messages.slice(-1)[0]
    this.setState({ messages: [...this.state.messages, ' ... '] })

    const path = (url === 0) ? '/api?question=' + question : url

    axios.get(path).then(function (response) {
      self.state.messages.pop();
      self.setState({ messages: [...self.state.messages, self.format_result(response.data)], showInput: true })
    })
  }

  selectTag = (url) => {
    const tag = url.split("&selected_tag=")[1];
    this.setState({ messages: [...this.state.messages, tag.replace("+", " ")], showInput: false })
    let self = this
    setTimeout(() =>{ self.answer(url) }, Math.random()*300)
  }

  sendEmail = (messages) => {
    const token = document.querySelector('[name=csrf-token]').content
    axios.defaults.headers.common['X-CSRF-TOKEN'] = token
    axios.post('/api/send_email', {messages: messages})
  }

  format_result = (result) => {
    const { noAnswer, messages } = this.state
    let message = ""
    let links = ""

    if(result.length != 0) {
      links = result.map((link, index) => {
        if(result[0].url.indexOf('/api') === 0) {
          message = INDEX + botMessages('choose_tag',this.gender_state)
          return <li key={index}><button onClick={() => this.selectTag(link.url)}>{link.title}</button></li>
        } else {
          message = LIKE + botMessages('found',this.gender_state)
          return <li key={index}><a href={link.url} target="_blank">{link.title}</a></li>
        }
      })
      this.setState({noAnswer: 0})
    } else {
      switch (noAnswer) {
        case 0:
          message = THINK + botMessages('not_found')
        break;
        case 1:
          message = ROLL + botMessages('ask_for_email')
        break;
        case 2:
          message = "Hvala" + HEARTS + "! " + botMessages('thanks')
          const total = messages.length
          this.sendEmail([messages[total-5], messages[total-3], messages[total-1]])
          break;
      }
      this.setState({noAnswer: noAnswer + 1})
    }

    return <>
      {message}
      {links !== '' &&
        <ul className="sezam_results">{links}</ul>
      }
      </>
  }

  render() {

    const {messages, showInput} = this.state

    return <div className="sezam">
      <header>
        <div className="button_container">
          <a href="/" className="button"><img src={arrow} /> Vrati se na klasičnu stranicu</a>
        </div>
      </header>
      <section className="page sezam_theme">
        <div className="row">
          <div className="col_2"></div>
          <main className="col_8">
            <div className="page_body">
              <Messages messages={messages} />
            </div>
          </main>
          <div className="col_2"></div>
        </div>
      </section>
      <footer>
        <div className="row">
          <Input handleKeyDown={this.handleKeyDown} sendMessage={this.sendMessage} visable={showInput} messageInput={this.messageInput} />
        </div>
      </footer>
    </div>

  }

}

export default Chat
